import clsx from 'clsx'
import type { ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const customTwMerge = extendTailwindMerge({
  override: {},
  extend: {
    theme: {
      spacing: ['xs'],
    },
    classGroups: {
      'max-w': [{ 'max-w': ['modal', 'container', 'screen'] }],
      'max-h': [{ 'max-h': ['modal', 'screen'] }],
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs)) || undefined
}
